import { useRef, useState } from 'react';
import { Link } from 'gatsby';
import {
  Row, Col, Carousel, Form, Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import * as styles from '../styles/index.module.scss';
import { subscribe } from '../api/SubscribeAPI';

function IndexPage() {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const refIsSubmitting = useRef(false);

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleSubmit = async (evt) => {
    if (refIsSubmitting.current) {
      return true;
    }

    const form = evt.currentTarget;
    evt.preventDefault();
    evt.stopPropagation();
    setValidated(true);

    if (form.checkValidity()) {
      refIsSubmitting.current = true;
      setIsSubmitting(true);
      await subscribe({ email })
        .then(() => {
          toast.dark('Thanks for your subscribe! 😀');
        })
        .catch(() => {
          toast.dark(
            "Oops! There's something wrong, please try it later. If it's still not working, please let us know. 🙏",
          );
        })
        .then(() => {
          refIsSubmitting.current = false;
          setIsSubmitting(false);
        });
    }

    return true;
  };

  return (
    <Layout>
      <SEO />
      <Row className="g-0">
        <Col>
          <Carousel>
            <Carousel.Item className={`${styles.carouselItem}`}>
              <div className={`${styles.slide} ${styles.firstSlide}`} />
              <Carousel.Caption className="text-start">
                <h3>Aura playing cards</h3>
                <Button variant="secondary" as={Link} to="/products/">
                  Check it!
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className={`${styles.slide} ${styles.secondSlide}`} />
              <Carousel.Caption className="text-start">
                <h3>THE WALTTY DECK</h3>
                <Button as={Link} to="/products/">
                  Check it!
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className={`${styles.slide} ${styles.thirdSlide}`} />
              <Carousel.Caption className="text-start">
                <h3>WALTTY PLAYING CARD STUDIO</h3>
                <Button as={Link} to="/about/">
                  About Us
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row className="text-center section sectionDark g-0">
        <Col className="mx-auto" xs={10} lg={6}>
          <h2>About Waltty</h2>
          <h5>
            We love playing cards! The reason why we established Waltty is that
            simple!
          </h5>
          <p>
            Waltty Playing Card Studio was founded in the end of 2015 by six
            friends in Taiwan. We have been doing magic, cardistry and
            collecting playing cards for almost 10 years. We believe that is an
            important part of our lives. Everyone who loves playing cards has
            his or her style. We want to share our ideas with you on this unique
            lifestyle. Our goal is simple: to create something touching in
            playing cards.
          </p>
        </Col>
      </Row>
      <Row className="text-center section sectionLight g-0">
        <Col className="mx-auto" xs={10} lg={6}>
          <h2>Follow Us</h2>
          <h5>
            Follow us on social media to keep up to date with our latest news,
            events and more information.
          </h5>
          <p>
            If you would like to share photos/videos with us via Instagram, you
            can tag us(@walttyplayingcardstudio) or use #waltty hashtag in your
            post. We will check it out.
          </p>
        </Col>
      </Row>
      <Row className="section sectionMedium g-0">
        <Col className="mx-auto" xs={10} lg={6}>
          <h2>We love playing cards</h2>
          <h5>And you?</h5>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col} controlId="validationCustom01">
                <Form.Control
                  required
                  type="email"
                  placeholder="Your Email"
                  disabled={isSubmitting}
                  onChange={handleEmailChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className="d-grid mt-2">
              <Button type="submit" disabled={isSubmitting}>
                Subscribe
              </Button>
            </div>
          </Form>
          <p className="small text-muted mt-1">
            By submitting this form, you are agreeing to receive Waltty Playing
            Card Studio messages. You are always free to easily unsubscribe or
            customize your email preferences at any time. If you have any
            further questions, please
            {' '}
            <Link to="/contact/">contact us</Link>
            .
          </p>
        </Col>
      </Row>
    </Layout>
  );
}

export default IndexPage;
